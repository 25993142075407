<template>
  <div>
    <b-card header="求L/C" header-bg-variant="tmprimary" header-text-variant="white">
      <b-form inline>
        <b-input-group prepend="频率 f">
          <b-form-input id="input_f" v-model="input_f" type="text" />
          <b-input-group-append>
            <b-form-select id="input_funit" v-model="input_funit">
              <option value="1" selected>Hz</option>
              <option value="1000">kHz</option>
              <option value="1000000">MHz</option>
              <option value="1000000000">GHz</option>
              <option value="1000000000000">THz</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
        &nbsp;
        <b-input-group>
          <b-input-group-prepend is-text>
            感抗 X<sub>L</sub> / 容抗 X<sub>C</sub>
          </b-input-group-prepend>
          <b-form-input id="input_rval" v-model="input_rval" type="text" />
          <b-input-group-append>
            <b-form-select id="input_runit" v-model="input_runit">
              <option value="1" selected>Ω</option>
              <option value="1000">kΩ</option>
              <option value="1000000">MΩ</option>
              <option value="1000000000">GΩ</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <hr />
      <b-form inline>
        <b-input-group prepend="电感 L">
          <b-form-input id="output_l" v-model="output_l" type="text" readonly />
          <b-input-group-append>
            <b-form-select id="input_lunit" v-model="input_lunit">
              <option value="1">H</option>
              <option value="0.001" selected>mH</option>
              <option value="0.000001" selected>μH</option>
              <option value="0.000000001">nH</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
        &nbsp;
        <b-input-group prepend="电容 C">
          <b-form-input id="output_c" v-model="output_c" type="text" readonly />
          <b-input-group-append>
            <b-form-select id="input_cunit" v-model="input_cunit">
              <option value="1">F</option>
              <option value="0.001">mF</option>
              <option value="0.000001" selected>μF</option>
              <option value="0.000000001">nF</option>
              <option value="0.000000000001">pF</option>
            </b-form-select>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </b-card>
  </div>
</template>
<script>
const pi = 3.14159265358979;
export default {
  data() {
    return {
      input_f: "0",
      input_funit: "1",
      input_rval: "0",
      input_runit: "1",
      input_lunit: "0.001",
      input_cunit: "0.000001",
    };
  },
  computed: {
    output_l() {
      var af = Number(this.input_f) * Number(this.input_funit)
      var ax = Number(this.input_rval) * Number(this.input_runit)
      var al = ax / (2 * pi * af)
      return al / Number(this.input_lunit)
    },
    output_c() {
      var af = Number(this.input_f) * Number(this.input_funit)
      var ax = Number(this.input_rval) * Number(this.input_runit)
      var rac = 2 * pi * af * ax
      var ac = 1 / rac
      return ac / Number(this.input_cunit)
    }
  }
};
</script>