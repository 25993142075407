<template>
  <div>
    <LC001 />
    <br />
    <LC002 />
  </div>
</template>
<script>
import LC001 from "@/views/submods/basic_indcapres/lc001.vue"
import LC002 from "@/views/submods/basic_indcapres/lc002.vue"

export default {
  components: { LC001, LC002 }
}
</script>